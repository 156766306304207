<template>
  <div class="mb-4">
    <div class="box">
      <div class="box-header text-h4 font-weight-bold">
        {{ title || 'คำแนะนำ' }}
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <component v-if="component" :is="component" />
      <div v-else class="box-body" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuidingModal',

  components: {},

  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    component: {
      type: Object,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  created() {},

  computed: {},

  methods: {},
};
</script>

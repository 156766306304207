<template>
  <section class="container-fluid mt-4">
    <div class="box">
      <div class="box-header d-flex justify-content-between">
        <h5 class="font-weight-bold text-left d-flex">
          <a class="btn-red x-4 mr-3 pointer" @click="$router.go(-1)">
            <eva-icon class="eva-red" name="arrow-back-outline"></eva-icon>
          </a>
          ภาคผนวก
          <span
            class="pointer text-blue ml-3 font-weight-normal"
            @click="showGuidingModal()"
          >
            <u>ดูคำแนะนำ</u>
          </span>
        </h5>
        <div>
          <router-link
            :to="{ name: 'peno.preview' }"
            class="btn btn-outline-red py-1 px-4 mr-3"
          >ดูร่างแผน ฯ
          </router-link>
          <button v-show="allowEdit" v-promise-btn class="btn btn-action py-1 px-4" @click="save">
            บันทึก
          </button>
        </div>
      </div>
      <div class="box-body">
        <div
          class="form-group row"
          v-for="(appendix, index) in credentials.$v.appendixes.$each.$iter"
          v-bind:key="index"
        >
          <label class="col-form-label col-1 font-weight-bold"
          >{{ parseInt(index) + 1 }}.</label
          >
          <div class="col-10">
            <input
              type="text"
              class="form-control"
              v-model="appendix.content.$model"
              :readonly="!allowEdit"
            />
            <div class="error text-danger" v-if="appendix.content.$dirty">
              <div v-if="!appendix.content.required">โปรดระบุ</div>
              <div v-if="!appendix.content.maxLength">
                เกินจำนวนตัวอักษรที่กำหนด
              </div>
            </div>
          </div>
          <div class="col-1">
            <button
              v-show="allowEdit"
              type="button"
              @click="removeAppendix(index)"
              class="btn btn-outline-red"
            >
              ลบ
            </button>
          </div>
          <div class="col-3 offset-1 mt-2" v-if="appendix.content.$model">
            <div class="input-group mb-3 custom-file" v-show="allowEdit">
              <input
                type="file"
                class="custom-file-input"
                multiple
                @input="addFileToAppendix($event, appendix)"
              />
              <label class="custom-file-label">
                แนบไฟล์ (ขนาดไม่เกินไฟล์ละ 10 mb)
              </label>
            </div>
          </div>
          <div
            class="col-sm-7 col-12 mt-1"
            v-if="
              isReady &&
              appendix.$model.media &&
              appendix.$model.media.length > 0 &&
              appendix.content.$model
            "
          >
            <div
              class="form-control mt-1"
              v-for="(appendixFile, fileIndex) in appendix.$model.media"
              :key="fileIndex"
            >
              <div class="row">
                <div class="col-sm-10 col-6 text-left">
                  <a :href="appendixFile.url" target="_blank">
                    {{ appendixFile.file_name }} ขนาด ({{ appendixFile.size }})
                  </a>
                </div>
                <div class="col-sm-2 col-6 text-right">
                  <button
                    v-show="allowEdit"
                    @click="removeAppendixFile(index, appendixFile)"
                    type="button"
                    class="btn btn-sm text-sm btn-outline-red"
                  >
                    ลบไฟล์
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <button v-show="allowEdit" class="btn btn-red" @click="addAppendix">
              <eva-icon name="plus-circle-outline"></eva-icon>
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import Form from '../../../modules/form/form';
import {nth, reduce, cloneDeep, get} from 'lodash';
import {required, maxLength} from 'vuelidate/lib/validators';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingAppendix from '@/components/guiding/GuidingAppendix.vue';
import axios from 'axios';

export default {
  name: 'Appendix',

  components: {},
  data() {
    return {
      isReady: false,
      credentials: new Form({
        data: () => {
          return {
            appendixes: [],
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          appendixes: {
            $each: {
              content: {
                required,
                maxLength: maxLength(65000),
              },
            },
          },
        },
      }),
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState('peno', ['currentReport', 'allowEdit']),
    ...mapGetters('peno', ['currentProvince']),
    report_id() {
      return this.currentReport.id;
    },
    appendixes() {
      return this.currentReport.appendixes;
    },
    isDirty() {
      if (this.credentials.appendixes.length != this.appendixes.length) {
        return true;
      }
      const dirty = find(this.appendixes, (result, value, key) => {
        const compare = nth(this.credentials.appendixes, key);
        return value.content != compare.content;
      });
      return Boolean(dirty);
    },
  },
  methods: {
    async init() {
      this.credentials.appendixes = cloneDeep(this.appendixes);
      for (let index = 0; index < this.credentials.appendixes.length; index++) {
        const appendix = this.credentials.appendixes[index];
        await this.fetchFiles(appendix);
        if (index == this.credentials.appendixes.length - 1) {
          this.isReady = true;
        }
      }
    },
    showGuidingModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingAppendix,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    addAppendix() {
      this.credentials.appendixes.push({
        content: '',
      });
    },
    async fetchFiles(appendix) {
      const result = await axios.get(`/api/media/appendix/${appendix.id}`);
      if (result.status === 200 && result.data) {
        if (!appendix.media) {
          appendix.media = [];
        }
        result.data.forEach((e) => {
          appendix.media.push({
            file_name: e.data.file_name,
            size: e.data.size,
            media_id: e.data.media_id,
            url: e.data.url,
          });
        });
      }
    },
    async addFileToAppendix(event, appendix) {
      const addFiles = event.target.files;
      if (addFiles) {
        if (!appendix.$model.media) {
          appendix.$model.media = [];
        }

        for (let i = 0; i < addFiles.length; i++) {
          const formData = new FormData();
          if (addFiles[i].size > 10000000) {
            alert('ขนาดไฟล์ ' + addFiles[i].name + ' ใหญ่กว่า 10 mb');
            continue;
          }
          formData.append(`media_file`, addFiles[i]);
          formData.append('appendix_id', appendix.$model.id ?? '');
          formData.append('content', appendix.$model.content ?? '');
          const postAxios = axios.post(
            '/api/media/report/' + this.currentReport.id + '/appendix',
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
          );
          if (!appendix.$model.id) {
            const result = await postAxios;
            this.addFileFromResponse(result, appendix);
          } else {
            postAxios
              .then((result) => {
                this.addFileFromResponse(result, appendix);
              })
              .catch((error) => {
                alert(error);
              });
          }
        }
      }
      event.target.value = null;
    },
    addFileFromResponse(result, appendix) {
      this.isReady = false;
      if (result.status === 200) {
        const data = result.data;
        appendix.$model.id = data.appendix_id;
        appendix.$model.media.push({
          file_name: data.media.data.file_name,
          size: data.media.data.size,
          media_id: data.media.data.media_id,
          url: data.media.data.url,
        });
      }
      this.isReady = true;
    },
    removeAppendix(index) {
      this.credentials.appendixes.splice(index, 1);
    },
    removeAppendixFile(appendixIndex, appendixFile) {
      this.isReady = false;
      let index = this.credentials.appendixes[appendixIndex].media.findIndex(
        (e) => {
          return e.media_id === appendixFile.media_id;
        }
      );
      this.credentials.appendixes[appendixIndex].media.splice(index, 1);
      this.isReady = true;
    },
    save() {
      return this.credentials
        .patch('/api/current_report/appendixes/update')
        .then((data) => {
          this.$store.commit('peno/updateAppendixes', data);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
